<template>
  <div class="referrals">
    <div class="filters">
      <div class="search">
        <label for="search">Cerca</label>
        <input
          id="search"
          type="text"
          name=""
          placeholder="Suggerito, Referenziatore, CF Referenziatore, Azienda, P.IVA"
          @input="filterSearch($event)"
        >
      </div>

      <div class="date-box">
        <label for="date-from">Data inizio</label>
        <input id="date-from" type="date" @input="dateFromChanged($event)">
      </div>

      <div class="date-box">
        <label for="date-to">Data fine</label>
        <input id="date-to" type="date" @input="dateToChanged($event)">
      </div>

      <div class="lens">
        <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
      </div>
    </div>
    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th style="width: 50px">
              <input type="checkbox" :checked="false" @click="selectAll()">
            </th>
            <th
              v-for="(header,index) of headers"
              :key="header"
              class="orderable"
              @click="changeOrder(header, getKey(index))"
            >
              <div>
                <span :class="[{'text-break' : index === 6 || index === 8}, {'text-break-large' : index === 3 || index ===7 }]">{{ header }}</span>
                <font-awesome-icon
                  v-if="filters.order.field == header"
                  :icon="
                    filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'
                  "
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for="obj in getFilteredRows" :key="obj.id">
            <td> 
              <input type="checkbox" :checked="unlockSelected.indexOf(obj.id)!=-1" @click="selectRecord(obj.id)">
            </td>
            <td>
              <span v-html="convertDate(obj.data)" />
            </td>
            <td><span v-text="getName(obj.suggerito)" /></td>
            <td><span v-text="getName(obj.referenziatore)" /></td>
            <td><span v-text="obj.codiceFiscale" /></td>
            <td><span v-text="obj.ragioneSociale" /></td>
            <td><span v-text="obj.piva" /></td>
            <td class="text-r">
              <span v-text="obj.nettoAzienda ?? 0 + ' €'" />
            </td>
            <td class="text-r">
              <span v-text="obj.nettoReferenziatore ?? 0 + ' €'" />
            </td>
            <td class="text-r">
              <span v-text="obj.cving ?? 0 + ' €'" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <floating-button :menu="false" :tooltip="'Scarica i report'" @action="getCSV()" />
  </div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/it'
import csvDownload from 'json-to-csv-export'

import { reportbackoffice, sbapi, sbapibackoffice } from '@/api'
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import { reactive, ref } from '@vue/reactivity'
import { computed, inject, onMounted } from '@vue/runtime-core'

export default {
  setup() {
    let unlock = ref([])
    let unlockSelected = reactive([])
    const filters = reactive({
      order: {
        field: 'Data di sblocco',
        k: 'date',
        asc: false
      },
      search: [],
      dateFrom: null,
      dateTo: null
    })
    let pdfUrlToOpen = ref(null)
    const headers = [
      'Data di sblocco',
      'Suggerito',
      'Referenziatore',
      'CF Referenziatore',
      'Azienda',
      'P.IVA',
      '€ Azienda',
      '€ Referenziatore',
      '€ CVing'
    ]
    const spinner = spinnerStore()
    const navbar = navbarStore()
    const toast = inject('$toast')

    // ref to the dialog component
    const pdfPreviewDialog = ref('pdfPreviewDialog')

    /**
     * Set the order condition for the table
     * @param {number} orderBy - the column to order by
     */
    const changeOrder = (orderBy, k) => {
      if (filters.order.field == orderBy) {
        filters.order.asc = !filters.order.asc
      } else {
        filters.order.field = orderBy
        filters.order.k = k
        filters.order.asc = true
      }
    }

    /**
     * manage the search input
     * @param {Event} event - the oninput input event
     */
    const filterSearch = ({ target }) => {
      filters.search = target.value ? target.value.split(' ') : []
    }

    const getFilteredRows = computed(() => {
      const rows = unlock.value
        .filter((f) => {
          // if the filter is empty each row is allowed
          if (!filters.search.length) return true

          let allFiltersArePresentInRow = true

          for (const filter of filters.search) {
            //check suggested
            let n = f.suggerito
              .toLowerCase()
              .includes(filter.toLowerCase())

            //check refferal
            let l = f.referenziatore
              .toLowerCase()
              .includes(filter.toLowerCase())

            //check company
            let co = f.ragioneSociale.toLowerCase().includes(filter.toLowerCase())

            //check campaign
            let ca = f.codiceFiscale?.toLowerCase().includes(filter.toLowerCase())

            //check user cving
            let u = f.piva?.toString()
              .toLowerCase()
              .includes(filter.toLowerCase())

            allFiltersArePresentInRow =
              allFiltersArePresentInRow && (n || l || ca || co || u)

            if (!allFiltersArePresentInRow) break
          }

          return allFiltersArePresentInRow
        })
        .filter(({ date }) =>
        {return !filters.dateFrom ? true : moment(date, 'YYYY-MM-DDTHH:mm:ss') >= filters.dateFrom}
        )
        .filter(({ date }) => (!filters.dateTo ? true : moment(date, 'YYYY-MM-DDTHH:mm:ss') <= filters.dateTo))
        .sort(orderByColumnValues)

      return rows
    })

    const orderByColumnValues = (a, b) => {
      //todo implementare asc desc +true +false ?
      switch (filters.order.field) {
        case headers[0]: // date
          if (a.data < b.data)
            return filters.order.asc ? -1 : 1
          if (a.data > b.data)
            return filters.order.asc ? 1 : -1
          break
        case headers[1]: //suggested
          if (a.suggerito.toLowerCase() < b.suggerito.toLowerCase()) return filters.order.asc ? -1 : 1
          if (a.suggerito.toLowerCase() > b.suggerito.toLowerCase()) return filters.order.asc ? 1 : -1
          break
        case headers[2]: //refferal
          if (a.referenziatore.toLowerCase() < b.referenziatore.toLowerCase()) return filters.order.asc ? -1 : 1
          if (a.referenziatore.toLowerCase() > b.referenziatore.toLowerCase()) return filters.order.asc ? 1 : -1
          break
        case headers[3]: // codiceFiscale
          if (a.codiceFiscale.toLowerCase() < b.codiceFiscale.toLowerCase()) return filters.order.asc ? -1 : 1
          if (a.codiceFiscale.toLowerCase() > b.codiceFiscale.toLowerCase()) return filters.order.asc ? 1 : -1
          break
        case headers[4]: // company
          if (a.ragioneSociale.toLowerCase() < b.ragioneSociale.toLowerCase()) return filters.order.asc ? -1 : 1
          if (a.ragioneSociale.toLowerCase() > b.ragioneSociale.toLowerCase()) return filters.order.asc ? 1 : -1
          break
        case headers[5]: // piva
          if (a[filters.order.k].toLowerCase() < b[filters.order.k].toLowerCase())
            return filters.order.asc ? -1 : 1
          if (a[filters.order.k].toLowerCase() > b[filters.order.k].toLowerCase())
            return filters.order.asc ? 1 : -1
          break
        case headers[6]: // euro azienda
        case headers[7]: // euro referenziatore
        case headers[8]: // euro cving
          if ( Number(a[filters.order.k]) < Number(b[filters.order.k]))
            return filters.order.asc ? -1 : 1
          if (Number(a[filters.order.k]) > Number(b[filters.order.k]))
            return filters.order.asc ? 1 : -1
          break
      }
    }

    const convertDate = (date) => {
      return (
        moment(date, 'YYYY-MM-DDTHH:mm:ss').format('DD MMMM YYYY') +
        '<p style="font-size:13px">' +
        moment(date, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm:ss') +
        '</p>'
      )
    }

    const dateFromChanged = ({ target }) => {
      filters.dateFrom = new Date(target.value)
    }

    const dateToChanged = ({ target }) => {
      filters.dateTo = new Date(target.value)
    }

    const downloadPDF = (cid, uid, type = 0) => {
      spinner.show()
      sbapi
        .get('/auth/referred/document/url', {
          withCredentials: true,
          params: {
            cid,
            uid,
            type
          }
        })
        .then((response) => {
          pdfUrlToOpen.value = response.data
          pdfPreviewDialog.value.open(pdfUrlToOpen.value)
        })
        .catch(() => {
          toast.error('Qualcosa è andato storto')

        })
        .then(() => spinner.hide())
    }

    const getCandidateName = (name, surname) => {
      let n = name + ' ' + surname
      if (n.length > 25) {
        n = n.slice(0, 22)
        n = n + '...'
      }
      return n
    }
    const getCVName = (name, type) => {
      let c = name
      if (c.length > 10) {
        c = c.slice(0, 7)
        if (type === 'cv') {
          c += '...CV'
        } else {
          c += '...anonCV'
        }
      } else {
        if (type === 'cv') {
          c += '.CV'
        } else {
          c += '.anonCV'
        }
      }
      return c
    }
    const getName = (name) => {
      let c = name
      if (c.length > 20) {
        c = c.slice(0, 17)
        c += '...'
      }
      return c
    }

    onMounted(() => {
      navbar.title = 'Sblocchi'
      spinner.show()
      
      sbapibackoffice
        .get('/accounting/companyunlocks', {
          withCredentials: true
        })
        .then((response) => {
          unlock.value = response.data
        })
        .catch((error) => {
          toast.error('Qualcosa è andato storto')
          console.log(error)
        })
        .then(() => spinner.hide())

      spinner.hide()
    })

    const selectAll = () => {
      if (unlockSelected.length === unlock.length) {
        unlockSelected.splice(0)
      } else {
        Object.assign(
          unlockSelected,
          [...new Set(unlock.map((u) => u.id))]
        )
      }
    }

    const selectRecord = (id) => {
      if (unlockSelected.indexOf(id) == -1) {
        unlockSelected.push(id)
      } else {
        unlockSelected.splice(1, unlockSelected.indexOf(id))
      }
    }

    const getKey = (index) =>{
      let rows = getFilteredRows
      if(rows && rows.length>0){
        return Object.keys(rows[0])[index+1]
      }
      return ''
    }

    const getCSV = () =>{
      spinner.show()
      reportbackoffice.get('/accounting/csv?reportType=companyUnlocking')
        .then((response) => {
          console.log(response)
          let array = response.data.split('\r\n')
          let header = JSON.parse(JSON.stringify(array[0])).split(',')
          array.shift() 

          let dataFormatted = []
          array.forEach((el, index)=> {
            let data = el.split(',')
            data.forEach((record, i)=>{
              let k = header[i]
              if(dataFormatted[index]) {
                dataFormatted[index][k] = record
              } else {
                dataFormatted.push({
                  [k] :record
                })
              }
            })
          })
          const dataToConvert = {
            data: dataFormatted,
            filename: 'sblocchi__'+moment().format('DD-MM-YY'),
            delimiter: ',',
            headers: header
          }
          console.log(dataToConvert)
          csvDownload(dataToConvert)
        })
        .catch((error) => {
          toast.error('Qualcosa è andato storto, ', error)
        })
        .finally(() => spinner.hide())
    }
    return {
      getKey,
      selectRecord,
      unlockSelected,
      selectAll,
      unlock,
      filters,
      pdfUrlToOpen,
      headers,
      spinner,
      navbar,
      changeOrder,
      filterSearch,
      getFilteredRows,
      convertDate,
      dateFromChanged,
      dateToChanged,
      downloadPDF,
      pdfPreviewDialog,
      getCandidateName,
      getName,
      getCVName,
      getCSV
    }
  }
}
</script>

<style lang="scss" scoped>
@use "../../assets/scss/referrals";
@use "../../assets/scss/table";
.referrals table thead,
.referrals table thead tr:first-child,
.referrals table thead tr:first-child th {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.referrals table tr th:first-child,
.referrals table .table-body tr td:first-child {
  padding-left: 1.5rem !important;
}
.text-xs {
  font-size: 13px;
  margin-top: 4px;
}
.text-r {
  text-align: right;
}
.text-break {
      word-break: break-word;
    width: 60px;
    white-space: break-spaces;
    text-align: center;
}
.text-break-large {
      word-break: break-word;
    width: 100px;
    white-space: break-spaces;
    text-align: center;
}
</style>
